<template>
  <AppPage container :title="$vuetify.lang.t('$vuetify.edit_profile')">
    <h2 class="mb-4">{{ $vuetify.lang.t("$vuetify.edit_profile") }}</h2>
    <v-form v-model="isValid" @submit.prevent="saveChanges">
      <v-text-field
        v-model="form.name"
        outlined
        hide-details
        class="rounded-lg mb-3"
        :rules="[rules.required]"
        :label="$vuetify.lang.t('$vuetify.name')"
      ></v-text-field>

      <v-text-field
        v-model="form.email"
        outlined
        hide-details
        type="email"
        class="rounded-lg mb-3"
        :rules="[rules.required, rules.validEmail]"
        :label="$vuetify.lang.t('$vuetify.email')"
      ></v-text-field>

      <VuePhoneNumberInput
        v-model="form.contact"
        error-color="#FF5F5F"
        valid-color="#0DBFF2"
        color="#0DBFF2"
        size="lg"
        :translations="translations"
        required
        :border-radius="8"
        no-example
        @update="validatePhone"
        :default-country-code="user.country_code || 'BE'"
        class="number-input"
      />

      <h3 class="mb-2 mt-5">{{ $vuetify.lang.t("$vuetify.gender") }}</h3>
      <ToggleBtn v-model="form.gender" :lists="genderList" />

      <app-button
        type="submit"
        :disabled="!isValid || !validPhone"
        :loading="loading"
      >
        {{ $vuetify.lang.t("$vuetify.save") }}
      </app-button>
    </v-form>
  </AppPage>
</template>

<script>
import { validEmail, required } from "@/helpers/form-rules";
import { GENDER_LIST } from "@/constants";

import ToggleBtn from "@/components/form/toggle-btn";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import { createNamespacedHelpers } from "vuex";
const userModule = createNamespacedHelpers("user");
export default {
  name: "edit-profile",
  components: {
    ToggleBtn,
    VuePhoneNumberInput,
  },
  data() {
    return {
      isValid: false,
      validPhone: false,
      translations : {
        countrySelectorLabel: this.$vuetify.lang.t("$vuetify.country_code"),
        phoneNumberLabel: this.$vuetify.lang.t("$vuetify.phone_number")
      },
      rules: {
        validEmail,
        required,
      },
      // genderList: GENDER_LIST,
      form: {
        name: "",
        email: "",
        contact: "",
        gender: "",
        country_code: "",
      },
      formatted_number: "",
    };
  },
  computed: { ...userModule.mapState(["user", "loading"]),
  genderList () {
    return GENDER_LIST.map((gender) => {
      let label = this.$vuetify.lang.t(`$vuetify.${gender.value}`);
      return {
        label: label,
        value: gender.value
      }
    });
  }
  },
  methods: {
    ...userModule.mapActions(["UPDATE_USER_PROFILE"]),
    validatePhone(phone) {
      const { countryCode, isValid, formattedNumber } = phone;
      this.validPhone = isValid;
      this.form.country_code = countryCode;
      this.formatted_number = formattedNumber;
    },
    async saveChanges() {
      if (!this.isValid)
        return window.showAlert(
          "Contact Number is not valid",
          "validation Error"
        );
      const payload = { ...this.form };
      if (this.user.email === this.form.email) {
        delete payload.email;
      }

      payload.contact = this.formatted_number;
      const resp = await this.UPDATE_USER_PROFILE(payload);
      if (!resp) {
        this.$emit("error");
      }
      this.$router.push("/profile");
    },
  },
  created() {
    for (const key in this.form) {
      const cpUser = { ...this.user };
      if (cpUser[key]) {
        this.form[key] = cpUser[key];
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
